@import './variables.scss';

.form-checkbox {
  display: inline-block;
  position: relative;
  &.form-checkbox--with-label {
    .form-checkbox-lbl {
      padding-left: 25px;
      min-height: 18px;
      &::after {
        top: 2px;
      }
    }
  }
  .input-checkbox {
    position: absolute;
    opacity: 0;
    z-index: 11;
    top: 4px;
    &:checked {
      + .form-checkbox-lbl {
        &::after {
          background-image: url('../assets/images/check.svg');
          background-position: center;
        }
      }
    }
  }
  .form-checkbox-lbl {
    margin-bottom: 0;
    position: relative;
    padding-left: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    min-height: 15px;
    color: $text-color-33;
    display: block;
    &::after {
      content: '';
      height: 15px;
      width: 15px;
      border: 1px solid $primary-color;
      border-radius: 4px;
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      background-color: $white;
    }
  }
}
.form-radio {
  &-lbl {
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
    line-height: rem(21);
  }
  .custom-radio {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    height: 21px;
    width: 21px;
    background-image: url('../assets/images/radio.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  .input-radio {
    position: absolute;
    opacity: 0;
    height: 21px;
    width: 21px;
    top: 0;
    left: 0;
    &:checked + .custom-radio {
      background-image: url('../assets/images/radio-check.svg');
    }
  }
}

.search-input {
  position: relative;
  input {
    height: 46px;
    width: 100%;
    background-color: $white;
    border: none;
    border-radius: 10px;
    padding-left: 58px;
    @include text($text-color-grey);
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }
  .search-btn {
    position: absolute;
    left: 0;
    top: 11px;
    background-color: transparent;
    border: none;
    padding: 0 14px;
    border-right: 1px solid $blue-bg;
  }
}

.form-field {
  margin-bottom: 40px;
  position: relative;
  label {
    margin-bottom: 10px;
    @include text-h5($text-color-grey);
    font-weight: 400;
  }
  input {
    padding: 0 0 15px 0;
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid $light-gray-broder;
    background-color: transparent;
    @include text-h5($text-color);
    font-weight: 500;
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      color: $text-color;
      font-weight: 500;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $white inset !important;
      color: $text-color;
    }
    &:focus {
      border-bottom: 1px solid $primary-color;
    }
    &:focus-visible {
      outline: none;
    }
  }
  .mat-mdc-form-field-error {
    @include text-m($red);
    position: absolute;
  }
  &.input-right-icon {
    .input-icon {
      position: absolute;
      right: 10px;
      top: 26px;
    }
  }
}

.modal-open  {
  .ng-dropdown-panel {
    border: none;
    background-color: $blue-bg;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 10px 50px rgba($color: $black, $alpha: 0.05);
    &-items {
      padding: 15px 0;
      .ng-option {
        background-color: transparent;
        padding: 6px 15px;
        @include text-h5($text-color-grey);
        &.ng-option-marked,
        &.ng-option-selected {
          background-color: $blue-bg-20 !important;
          color: $text-color-grey !important;
        }
      }
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        padding: 0 3px 0 0;
        width: calc(100% - 40px);
        .ng-value {
          background-color: $blue-bg;
          border-radius: 100px;
          @include text($text-color-grey);
          letter-spacing: -0.02em;
          display: inline-flex;
          align-items: center;
          padding: 3px 20px 3px 10px;
          margin-right: 3px;
          margin-bottom: 2px;
          max-width: 100%;
          position: relative;
          .ng-value-icon {
            position: absolute;
            right: 2px;
          }
          .ng-value-label {
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            width: 100%;
            padding: 0;
          }

          .ng-value-icon.left {
            font-size: rem(16);
            border: none;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}  

.form-field-select {
  .ng-select {
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #e7e7e7;
    &.ng-select-focused .ng-select-container {
      box-shadow: none;
    }
    &.ng-select-disabled {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            color: $grey-99;
          }
        }
      }
    }
    .ng-select-container {
      background-color: transparent;
      border: none;
      height: auto !important;
      min-height: 33px;
      align-items: center;
      .ng-value-container {
        padding: 0 3px 0 0;
        width: calc(100% - 40px);
        .ng-value {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $text-color;
        }
        .ng-placeholder {
          letter-spacing: -0.02em;
          font-size: 14px;
          font-weight: 500;
          color: $text-color;
          position: relative;
          top: 0 !important;
          padding: 0 !important;
          overflow: hidden;
          width: calc(100% - 40px);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .ng-clear-wrapper {
      margin: auto 0;
      width: 10px;
    }
    .ng-arrow-wrapper {
      height: rem(18);
      width: rem(18);
      background-image: url('../assets/images/dropdown-grey.svg');
      background-repeat: no-repeat;
      background-position: center;
      margin: auto 10px auto 0;
      .ng-arrow {
        display: none;
      }
    }
    .ng-dropdown-panel {
      border: none;
      background-color: $blue-bg;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 10px 50px rgba($color: $black, $alpha: 0.05);
      &-items {
        padding: 15px 0;
        .ng-option {
          background-color: transparent;
          padding: 6px 15px;
          @include text-h5($text-color-grey);
          &.ng-option-marked,
          &.ng-option-selected {
            background-color: $blue-bg-20 !important;
            color: $text-color-grey !important;
          }
        }
      }
    }
    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          padding: 0 3px 0 0;
          width: calc(100% - 40px);
          .ng-value {
            background-color: $blue-bg;
            border-radius: 100px;
            @include text($text-color-grey);
            letter-spacing: -0.02em;
            display: inline-flex;
            align-items: center;
            padding: 3px 20px 3px 10px;
            margin-right: 3px;
            margin-bottom: 2px;
            max-width: 100%;
            position: relative;
            .ng-value-icon {
              position: absolute;
              right: 2px;
            }
            .ng-value-label {
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
              width: 100%;
              padding: 0;
            }

            .ng-value-icon.left {
              font-size: rem(16);
              border: none;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  .multiline-option-content {
    .ng-option-label {
      display: flex;
      flex-direction: column;
      span {
        @include text-h5(#979797);
      }
    }
  }
  .remove-selected-value {
    .ng-value-container {
      .ng-value {
        display: none !important;
      }
      .ng-placeholder {
        display: block;
      }
    }
  }
}

.form-check-box {
  display: inline-grid;
  .mat-mdc-checkbox {
    .mat-mdc-checkbox-checkmark-path {
      stroke: $primary-color !important;
    }
    .mat-mdc-checkbox-background {
      background-color: $white !important;
      border: 1px solid $primary-color !important;
    }
    .mat-mdc-checkbox-background,
    .mat-mdc-checkbox-frame {
      border-radius: 30% !important;
    }

    .mat-ripple {
      display: none !important;
    }
  }
}

.filters {
  margin-bottom: 20px;
  .filter-list-item {
    margin-bottom: 10px;
  }
  .filter-actions {
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    .btn {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.ng-select--filled {
  .ng-select {
    width: 100%;
    background-color: $blue-bg;
    border-radius: 10px;
    padding: 11px 0 11px 15px;
    .ng-select-container {
      background-color: transparent !important;
      border: none;
      min-height: 24px;
      height: auto !important;
      box-shadow: inherit !important;
      .ng-value-container {
        padding: 0 3px 0 0;
        width: calc(100% - 40px);
        .ng-value {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $text-color;
        }
        .ng-placeholder {
          letter-spacing: -0.02em;
          font-size: 14px;
          font-weight: 500;
          color: $text-color;
          position: relative;
          top: 1px !important;
          padding: 0 !important;
          overflow: hidden;
          width: calc(100% - 40px);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .ng-input {
          padding: 0 !important;
        }
      }
    }
    .ng-clear-wrapper {
      margin: auto 0;
      width: 10px;
    }
    .ng-arrow-wrapper {
      height: rem(18);
      width: rem(18);
      background-image: url('../assets/images/dropdown-grey.svg');
      background-repeat: no-repeat;
      background-position: center;
      margin: auto 10px auto 0;
      .ng-arrow {
        display: none;
      }
    }
    .ng-dropdown-panel {
      border: none;
      background-color: $blue-bg;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 10px 50px rgba($color: $black, $alpha: 0.05);
      &-items {
        padding: 15px 0;
        .ng-option {
          background-color: transparent;
          padding: 6px 15px;
          @include text-h5($text-color-grey);
          &.ng-option-marked,
          &.ng-option-selected {
            background-color: $blue-bg-20 !important;
            color: $text-color-grey !important;
          }
        }
      }
    }
    &.ng-select-multiple {
      .ng-select-container {
        box-shadow: inherit !important;
        .ng-value-container {
          padding: 0 3px 0 0;
          width: calc(100% - 40px);
          .ng-value {
            background-color: $blue-bg-20;
            border-radius: 100px;
            @include text($text-color-grey);
            letter-spacing: -0.02em;
            display: inline-flex;
            align-items: center;
            padding: 3px 20px 3px 10px;
            margin-right: 3px;
            margin-bottom: 2px;
            max-width: 100%;
            position: relative;
            .ng-value-icon {
              position: absolute;
              right: 2px;
            }
            .ng-value-label {
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
              width: 100%;
              padding: 0;
            }
            .ng-value-icon.left {
              font-size: rem(16);
              border: none;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  &.with-left-icon {
    position: relative;
    .ng-select {
      padding-left: 62px;
    }
    .left-icon {
      position: absolute;
      top: 50%;
      left: 0;
      border-right: 1px solid $blue-bg-20;
      padding: 0 10px;
      height: 24px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      transform: translate(0, -50%);
      .icon {
        display: inline-flex;
        svg {
          display: block;
        }
      }
    }
  }
}
.form-field-time {
  .ng-select-container {
    .ng-arrow-wrapper {
      background-image: url('../assets/icons/clock-icon.svg');
    }
  }
}
.selected-tags {
  margin: 20px 0;
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      background-color: $blue-bg;
      border-radius: 100px;
      @include text($text-color-grey);
      letter-spacing: -0.02em;
      display: inline-block;
      padding: 5px 10px;
      margin-right: 10px;
    }
  }
}
.form-field-date {
  label {
    width: 100%;
  }
  input {
    border: none;
  }
  .mat-mdc-form-field {
    width: 100%;
    &-underline {
      background-color: $light-gray-broder;
      bottom: 0;
    }
    &-ripple {
      background-color: transparent;
    }
    &.mat-focused {
      .mat-mdc-form-field-ripple {
        background-color: transparent;
      }
    }
    &-infix {
      width: 100%;
      border-top: none;
      padding: 0;
    }
    &-wrapper {
      padding-bottom: 0;
    }
  }
}
.mat-datepicker-toggle {
  height: 20px;
  width: 20px;
  background-image: url('../assets/icons/calendar.svg');
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  cursor: pointer;
  .mat-mdc-icon-button {
    display: none;
  }
}

.file-upload-input {
  position: relative;
  input.file {
    position: absolute;
    height: 50px;
    width: 193px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  .file-upload-input {
    padding: 9px 20px;
  }
}

.pdf-file-view {
  display: inline-flex;
  border-radius: 4px;
  padding: 7px 10px;
  background-color: #f4f4f4;
  align-items: center;
  width: 330px;
  margin-bottom: 20px;
  &-wrap {
    flex: 1 1 auto;
    padding: 0 5px;
    .pdf-file-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      width: 220px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .pdf-size {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #8b8b8b;
    }
  }
}

.rounding-label {
  font-size:0.875rem;
  line-height:1.125rem;
  font-weight:400;
  position:absolute;
}
