@import './variables.scss';
.auth-wrap {
  display: flex;
  min-height: 100vh;
  background-color: $blue-bg;
  &-left {
    width: 45%;
    padding: 47px 30px;
    background-color: $white;
    border-radius: 0 30px 30px 0;
    .logo {
      margin-bottom: 90px;
    }
    &-content {
      text-align: center;
      h1 {
        margin-bottom: 10px;
        font-size: rem(48);
        font-family: $tangerine;
      }
      img {
        max-width: 100%;
      }
    }
  }
  &-right {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 55px;
    @media #{$IpadP} {
      padding: 40px;
    }
    h1 {
      @include text-h1($primary-color, 23px);
      &.margin-0 {
        margin-bottom: 10px;
      }
    }
    h2 {
      @include text-h1($logo-blue, 40px);
    }
    h3 {
      color: $green-primary;
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }
    .mail-lock-icon {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }
    .auth-form {
      max-width: 410px;
      display: inline-block;
      width: 100%;
    }
    .waiting-time {
      margin-top: 20px;
      color: $red;
    }
    .resend-otp {
      margin-top: 20px;
      @include text-h5($primary-color);
      a {
        color: $green-primary;
        text-decoration: none;
        cursor: pointer;
      }
      &.disable {
        a {
          cursor: not-allowed;
          opacity: .5;
          &:hover {
            color: $green-primary;
          }
        }
      }
    }
  }
  .profile-page-right-content {
    .auth-form {
      max-width: 560px;
      .btn {
        max-width: 400px;
      }
    }
  }
  .back-link {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    text-align: center;
    .icon {
      margin-right: 7px;
      vertical-align: middle;
    }
    a {
      display: inline-block;
      @include text-h5(#2BD88A);
      text-decoration: none;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.auth-form {
  .form-field {
    position: relative;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    label {
      color: $label-color;
      font-weight: 400;
    }
    .mat-mdc-form-field-error {
      font-size: 0.8rem;
      line-height: 1rem;
    }
    .input-icon {
      position: absolute;
      top: 33px;
      left: auto;
      right: 0;
      &.password-icon {
        cursor: pointer;
      }
    }
    &.password-validation {
      margin-bottom: 60px;
    }
    input {
      padding: 0 25px 14px 0;
      border-bottom: 1px solid $blue-bg-20;
      @include text-l($primary-color);
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        color: $placeholder-color;
      } 
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus, 
      &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px $blue-bg inset !important;
          color: $primary-color;
      }
      &:focus {
        border-bottom: 1px solid $primary-color;
      }
    }
    &.error {
      .input-icon {
        stroke: $red;
      }
      label {
        color: $red;
      }
      input {
        border-bottom: 1px solid $red;
      }
    }
    &.form-field-otp {
      width: auto;
      margin-left: 15px;
      margin-bottom: 40px;
      &:first-child {
        margin-left: 0;
      }
      input {
        padding:7px;
        width: 55px;
        height: 55px;
        margin-right: 30px;
        border: 1px solid $blue-bg-20;
        border-radius: 15px;
        background-color: $white;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        &:focus {
          border: 1px solid $logo-blue;
        }
        &:last-child {
          margin-right: 0;
        }
        @media #{$Desktop-small} {
          width: 55px;
          height: 55px;
          margin-right: 20px;
          border-radius: 10px;
          font-size: rem(24);
          line-height: rem(24);
        }
        @media #{$IpadP} {
          width: 45px;
          height: 45px;
          margin-right: 10px;
          border-radius: 10px;
          font-size: rem(22);
          line-height: rem(22);
        }
      }
      @media #{$IpadP} {
        margin-left: 15px;
      }
    }
  }
  .remember-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 10px;
    .forgot-link {
      display: inline-block;
      a {
        display: inline-block;
        @include text-h5($green-primary);
        text-decoration: none;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
    &.password-validation {
      margin-top: 30px;
    }
    .user-does-not-exist {
      margin-top: 12px;
      @include text-h5($primary-color);
      a {
        color: $green-primary;
        text-decoration: none;
        cursor: pointer;
      }
      &.disable {
        a {
          cursor: not-allowed;
          opacity: .5;
          &:hover {
            color: $green-primary;
          }
        }
      }
    }
  }
  .form-field-select {
    display: inline-block;
    .form-field-dropdown {
      width: 100%;
    }
    .select-btn {
      padding: 0 25px 14px 0;
      width: 100%;
      border: none;
      border-bottom: 1px solid $blue-bg-20;
      background-color: transparent;
      text-align: left;
      @include text-l($placeholder-color);
      &:focus {
        outline: none;
        border-bottom: 1px solid $primary-color;
      }
      &::after {
        position: absolute;
        top: 6px;
        right: 0;
        left: auto;
        border: none;
        background-image: url('../assets/images/downarrow.svg');
        width: 14px;
        height: 14px;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    .dropdown-menu {
      margin-top: 0;
      background-color: $blue-bg;
      border-radius: 0 0 20px 20px;
      border: none;
      .dropdown-item {
        padding: 6px 15px;
        @include text-h5($text-color-grey);
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .ng-select.ng-select-multiple {   
    border-bottom-color: $blue-bg-20;
    .ng-select-container {
      box-shadow: none !important;
      background-color: transparent !important;
      .ng-value-container {
        .ng-value {
          background-color: $white;
        }
      }
    }
  }
  .otp-error-message, .client-forgot-password-error {
    @include text-h5($red);
    margin: 0 0 20px;
  }
}
.edit-profile {
  position: relative;
  margin-bottom: 30px;
  img {
    height: 80px;
    width: 80px;
    border-radius: 100%;
  }
  &-input {
    position: absolute;
    top: 54px;
    left: 54px;
    input {
      position: absolute;
      top: 0;
      left: 0;
      height: 26px;
      width: 26px;
      border-radius: 100%;
      opacity: 0;
    }
    &-icon {
      display: block;
      height: 26px;
      width: 26px;
      border-radius: 100%;
      background-image: url('../assets/images/camera-icon.svg');
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}