.mat-mdc-option.option-text-space-between .mdc-list-item__primary-text {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hj-chip {
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;

  font-size: 12px;
  font-weight: 400;

  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;

  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  color: rgb(30 66 159);
  background-color: rgb(225, 239, 254);
  border-color: rgb(118 169 250);
}

.hj-no-border {
  border: none;
}

.hj-chip-alt {
  color: rgb(31, 41, 55);
  background-color: rgb(243, 244, 246);

  border-color: rgb(107, 114, 128);
}

.hj-chip-red {
  color: rgb(155, 28, 28);
  background-color: rgb(253, 232, 232);
  border-color: rgb(249, 128, 128);
}

.hj-chip-green {
  color: rgb(3, 84, 63);
  background-color: rgb(222, 247, 236);
  border-color: rgb(49, 196, 141);
}

.hj-chip-yellow {
  color: rgb(114, 59, 19);
  background-color: rgb(253, 246, 178);
  border-color: rgb(250, 202, 21);
}

.hj-chip-purple {
  color: rgb(85, 33, 181);
  background-color: rgb(237, 235, 254);
  border-color: rgb(172, 148, 250);
}
