@import './variables.scss';

h1 {
    @include text-h1($primary-color, 0);
}
.no-data {
    @include text-m($text-color);
    padding: 15px;
}
.modal-form-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: $primary-color;
    margin-bottom: 30px;
}