@import './variables.scss';

.hj-tooltip-large {
    --mdc-plain-tooltip-container-color: #{$blue-bg-20};
    --mdc-plain-tooltip-supporting-text-color: $label-color;
    --mdc-plain-tooltip-supporting-text-line-height: 1.2em;
    --mdc-plain-tooltip-supporting-text-size: 0.8rem;
    --mdc-plain-tooltip-supporting-text-weight: 400;
    --mdc-tooltip-word-break: normal;
    --mdc-tooltip-height: auto;
}