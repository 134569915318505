/* You can add global styles to this file, and also import other style files */
@import './scss/variables';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'scss/main.scss';
@import 'ngx-toastr/toastr';

html,
body {
  height: 100%;
}
