@import './variables.scss';

.btn {
  padding: 15px 20px;
  background-color: $primary-color;
  border-radius: 10px;
  @include text-m($white);
  font-weight: 700;
  letter-spacing: -0.02em;
  &:hover {
    color: $white;
  }
  &:focus {
    box-shadow: none;
  }
}
.btn-lg {
  width: 100%;
}
.btn-sm {
  padding: 13px 16px;
}
.btn-outline {
  background-color: $blue-bg;
  border: 1px solid $primary-color;
  color: $text-color;
  &:hover,
  &:focus {
    color: $text-color;
  }
}
.btn-link {
  padding: 0;
  background-color: transparent;
  border: 0px;
  color: $text-color;
  font-weight: 400;
  &:hover,
  &:focus {
    color: $text-color;
    text-decoration: none;
  }
}

