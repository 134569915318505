@import './variables.scss';

.modal-backdrop {
  background-color: $primary-color;
  opacity: 0.15;
  z-index: 1040 !important;
}
.no-data-found {
  margin: 20px 0 0;
  text-align: center;
}
.modal {
  .modal-content {
    padding: 10px;
    border: none;
  }
  .modal-header {
    position: relative;
    padding: 20px;
    background-color: $blue-bg;
    border-radius: 15px;
    border: none;
    .close {
      position: absolute;
      top: 29px;
      right: 20px;
      margin: 0;
      padding: 0;
      opacity: 1;
    }
    .modal-title {
      font-size: rem(24);
      line-height: rem(42);
      letter-spacing: -0.02em;
      color: $primary-color;
      font-weight: 700;
    }
  }
  .modal-header,
  .manual-header {
    .modal-title-running-time {
      color: $primary-color;
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 20px;
    h3 {
      letter-spacing: -0.02em;
      @include text-h5($text-color);
      margin-bottom: 20px;
    }
    .btns-wrap {
      margin: 30px 0;
      .btn {
        width: calc(50% - 15px);
        &:not(.btn-outline) {
          margin-right: 30px;
        }
      }
    }
  }
}
.addrole-modal {
  .modal-dialog {
    max-width: 996px;
    width: 100%;
  }
}
.documents-upload-in-progress-modal {
  .modal-dialog {
    max-width: 600px;
    width: 100%;
  }
}
.delete-confirm-modal,
.deleterole-modal,
.documents-upload-in-progress-modal,
.delete-data-modal,
.update-matter-status-confirmation {
  .modal-header {
    padding: 0;
    background-color: transparent;
    .close {
      top: 10px;
      right: 10px;
      z-index: 999;
    }
  }
  .modal-body {
    padding: 50px 20px 20px;
    .confirm-message {
      margin-bottom: 40px;
      text-align: center;
      @include text-l($text-color-grey);
    }
    .btns-wrap {
      margin-bottom: 0;
    }
  }
}
.new-work-modal {
  .modal-dialog {
    max-width: 405px;
    width: 100%;
  }
  .form-field {
    margin-bottom: 10px;
  }
  .note {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $text-color-grey;
    margin-bottom: 30px;
  }
  .foot-btn {
    text-align: center;
    .btn {
      min-width: 160px;
    }
  }
}
.FA-modal {
  .modal-dialog {
    max-width: 635px;
    width: 100%;
  }
}
.status-dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
  &.red {
    background-color: #ff0000;
  }
  &.yellow {
    background-color: #ffbf00;
  }
  &.green {
    background-color: $green-primary;
  }
}
.success-info-modal {
  .modal-dialog {
    max-width: 635px;
    width: 100%;
  }
  .modal-content {
    position: relative;
    .close {
      position: absolute;
      top: 35px;
      right: 36px;
      opacity: 1;
      z-index: 99;
    }
    .modal-body {
      padding: 50px;
      text-align: center;
      .success-icon-thumb {
        margin: 0 auto 20px;
        display: block;
      }
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: -0.02em;
        color: $text-color-grey;
        margin: 0 0 10px;
      }
      p {
        @include text-m($text-color-grey);
        letter-spacing: -0.02em;
        strong {
          color: $text-color;
        }
      }
      .successfully-foot-btn {
        margin: 50px auto 0;
        &.max-w-341 {
          max-width: 341px;
        }
      }
    }
  }
}

.email-confirmed-modal {
  .modal-dialog {
    max-width: 635px;
    width: 100%;
  }
  .modal-content {
    position: relative;
    background-color: $blue-bg;
    > .close {
      position: absolute;
      top: 35px;
      right: 36px;
      opacity: 1;
      z-index: 99;
    }
    .modal-body {
      padding: 114px 45px 30px;
      text-align: center;
      h2 {
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(31);
        letter-spacing: -0.02em;
        color: $primary-color;
        margin: 0 0 50px;
        &.text-red {
          color: $red;
        }
      }
      p {
        @include text-l($text-color-grey);
        letter-spacing: -0.02em;
        font-weight: 700;
      }
      .successfully-foot-btn {
        margin: 50px auto 0;
        &.max-w-341 {
          max-width: 341px;
        }
      }
    }
  }
}

.saveconfirmation-modal {
  .modal-dialog {
    max-width: 456px;
  }
  .modal-body {
    h3 {
      max-width: 333px;
      margin: 0 auto 40px;
      font-size: rem(20);
      line-height: rem(24);
      color: $text-color-grey;
      text-align: center;
    }
  }
  &.temphour-modal {
    .modal-header {
      .modal-title {
        width: 100%;
        text-align: center;
      }
    }
    .modal-body {
      padding-top: 30px;
      padding-bottom: 30px;
      p {
        margin-bottom: 28px;
        font-size: rem(20);
        line-height: rem(21);
        color: $text-color;
        text-align: center;
        font-weight: 700;
      }
      button {
        width: 100%;
      }
    }
  }
  &.savefilter-modal {
    .modal-body {
      p {
        &:first-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.savedsuccessfully-modal {
  .modal-dialog {
    max-width: 456px;
  }
  .modal-content {
    position: relative;
    background-color: $blue-bg;
    > .close {
      position: absolute;
      top: 35px;
      right: 36px;
      opacity: 1;
      z-index: 99;
    }
    .modal-body {
      padding: 82px 35px 30px;
      text-align: center;
      h2 {
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(31);
        letter-spacing: -0.02em;
        color: $text-color-grey;
        margin: 0 0 10px;
      }
      p {
        margin-bottom: 30px;
        @include text-m($text-color-grey);
        letter-spacing: -0.02em;
        span {
          color: $text-color;
        }
      }
      .successfully-foot-btn {
        text-align: center;
        .btn {
          max-width: 183px;
          display: inline-block;
        }
      }
    }
  }
}
/* ************ Logtime modal start ************ */
.hour-log-modal {
  z-index: 1040;
  .modal-dialog {
    max-width: 640px;
    margin: 84px 30px 1.75rem auto;
  }
  .close {
    text-align: right;
  }
  .modal-body {
    padding: 10px 0 0;
  }
  .day-log {
    margin-bottom: 6px;
    padding: 20px;
    background-color: $blue-bg;
    border-radius: 15px;
    display: flex;
    align-items: center;
    p {
      margin: 0;
      font-size: rem(22);
      font-weight: 700;
      line-height: rem(40);
      color: $primary-color;
      letter-spacing: -0.02em;
      opacity: 0.47;
      flex: 0 0 44%;
    }
  }
  .log-title-box {
    padding: 20px;
    background-color: $blue-bg-20;
    border-radius: 15px;
    min-height: 82px;
    display: flex;
    align-items: center;
    &.today-total-time-details {
      justify-content: space-between;
    }
    p {
      margin: 0;
      font-size: rem(22);
      font-weight: 700;
      line-height: rem(40);
      color: $text-color;
      letter-spacing: -0.02em;
      flex: 0 0 44%;
      &.today-total-time-label,
      &.today-total-time {
        flex: none;
      }
    }
    .search-input {
      width: 100%;
      .search-btn {
        background-color: transparent;
        padding: 0;
        border: 0px;
      }
      input {
        padding: 0 36px;
        background-color: transparent;
        font-size: rem(22);
        font-weight: 700;
        line-height: rem(40);
        color: $text-color;
        letter-spacing: -0.02em;
        &::placeholder {
          color: $text-color;
        }
      }
      .close-btn {
        background: transparent;
        border: 0;
        position: absolute;
        right: 0;
        top: 11px;
      }
    }
  }
  .log-list {
    margin: 25px 26px 12px;
    max-height: 410px;
    overflow: auto;
    .log-list-tab {
      margin-bottom: 16px;
      background-color: $blue-bg;
      border-radius: 10px !important;
      box-shadow: none;
      &:last-child {
        margin: 0;
      }
      .mat-expansion-panel-header {
        padding: 12px 24px;
        height: auto;
        &:hover,
        &:focus {
          background-color: $blue-bg;
        }
      }
      &.mat-expanded {
        .mat-expansion-panel-body {
          padding: 10px 22px;
          background-color: $grey-f9;
        }
      }
    }
    .log-list-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      h6 {
        margin: 0 0 8px 0;
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(18);
        color: $text-color-grey;
      }
      p {
        margin: 0 0 8px 0;
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(18);
        color: $text-color;
        &:last-child {
          margin: 0;
        }
      }
      .timer-block {
        padding: 0;
        text-align: center;
        button {
          padding: 0px;
          background: transparent;
          border: 0px;
        }
      }
      .client-name-block {
        padding-left: 0;
      }
      .authorize-click {
        .play-btn {
          margin: 0 15px;
          max-width: none;
          width: auto;
        }
      }
      .hour-edit-btn {
        padding: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }
}
/* ************ Logtime modal end ************ */
/* ************ Manual request modal start ************ */
.manual-request-modal {
  .modal-body {
    padding: 0;
  }
  .modal-dialog {
    max-width: 955px;
  }

  .mat-mdc-tab-group {
    padding: 18px 30px 30px;
    .mat-mdc-tab-body-wrapper {
      padding: 30px 0 0 0;
    }
    .mat-mdc-tab-body-content {
      overflow-x: hidden;
    }
    .mat-mdc-tab-header {
      border: 0px;
    }
    .mat-mdc-tab-labels {
      .mat-mdc-tab-label {
        padding: 0 20px;
        flex: 0 0 50%;
        justify-content: flex-start;
        color: $label-color;
        font-family: $DMsans;
        font-weight: 700;
        border-bottom: 2px solid $label-color;
        &.mat-mdc-tab-label-active,
        &.mat-mdc-focus-indicator {
          color: $primary-color;
          opacity: 1;
        }
        &:focus,
        &:hover,
        &:visited {
          background-color: transparent;
        }
      }
    }
  }
  .manual-header {
    position: relative;
  }
  .manual-req-header {
    padding: 20px;
    background: $blue-bg;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 99;
    h6 {
      margin: 0;
      font-size: rem(22);
      font-weight: 700;
      line-height: rem(40);
      color: $primary-color;
      letter-spacing: -0.02em;
    }
  }
  .manual-req-warning {
    padding: 52px 20px 10px;
    background-color: $error-red;
    border-radius: 15px;
    margin-top: -40px;
    p {
      margin: 0;
      font-size: rem(13);
      font-weight: 400;
      line-height: rem(16);
      color: $white;
      letter-spacing: -0.4px;
    }
  }
  form {
    .form-field {
      margin-bottom: 20px;
    }
    .desc-input {
      padding-right: 38px;
      textarea {
        padding: 0 38px 15px 0;
        width: 100%;
        display: block;
        border: none;
        border-bottom: 1px solid $light-gray-broder;
        background-color: transparent;
        color: $text-color;
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 500;
      }
    }
    .date-input {
      display: flex;
      flex-flow: column;
      .mat-mdc-form-field-flex {
        padding: 0;
        background: $white;
        display: flex;
        align-items: center;
      }
      .mat-mdc-form-field-infix {
        padding: 0;
        margin: 0;
        width: 100%;
        border: 0px;

        input {
          border: 0;
          padding: 0;
        }
      }
      .mat-mdc-button-wrapper {
        svg {
          width: 20px;
          height: 20px;
          vertical-align: top;
        }
      }
      .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
      }
      .mat-mdc-form-field-underline {
        bottom: 3px;
        &::before {
          background-color: $light-gray-broder;
        }
        .mat-mdc-form-field-ripple {
          height: 1px;
          background-color: $primary-color;
        }
      }
    }
  }
  .mat-mdc-slide-toggle {
    .mdc-label {
      margin: 0 0 0 8px;
    }
  }
}
/* ************ Manual request modal end ************ */
/* ************ Timer modal start ************ */
.start-timer-modal {
  .modal-dialog {
    max-width: 456px;
    margin: 0 30px 30px auto;
    height: 96vh;
    display: flex;
    align-items: flex-end;
    text-align: center;
  }
  .modal-header {
    .modal-title {
      margin: 0 auto;
    }
  }
  .modal-body {
    h6 {
      margin: 10px 0 20px 0;
      font-size: rem(20);
      font-weight: 700;
      line-height: rem(21);
      color: $text-color;
    }
    p {
      margin: 0 0 30px 0;
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(21);
      color: $text-color-grey;
    }
    .btn {
      width: 100%;
    }
  }
}
/* ************ Timer modal end ************ */
/* ************ Edit Budget modal start ************ */
.edit-budget-modal {
  z-index: 1040;
  .modal-dialog {
    max-width: 796px;
  }
  .edit-budget-form {
    padding: 30px 20px;
    h6 {
      margin: 0 0 12px 0;
      font-size: rem(16);
      font-weight: 700;
      line-height: rem(21);
      color: $text-color-grey;
    }
    .blue-box {
      margin-bottom: 30px;
      padding: 33px 25px;
      background-color: $blue-bg;
      border-radius: 10px;
      &.blue-box-small {
        padding: 20px 20px 30px;
      }
    }
    label {
      margin: 0 0 8px 0;
      font-size: rem(13);
      font-weight: 400;
      line-height: rem(18);
      color: $text-color-grey;
    }
    p {
      margin: 0;
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(18);
      color: $text-color;
    }
    .border-r {
      border-right: 1px solid $blue-bg-20;
    }
    .row-top {
      margin-bottom: 30px;
    }
    .ng-select {
      &.ng-select-single {
        .ng-select-container {
          .ng-value-container {
            .ng-input {
              padding-left: 0;
            }
          }
        }
      }
    }
    .new-budget-col {
      .form-field {
        margin-bottom: 30px;
      }
    }
    .marginb-0 {
      margin-bottom: 0;
    }
  }
}
/* ************ Edit Budget modal end ************ */
/* ************ Edit Disbursement modal  ************ */
.disbursement-modal {
  .modal-dialog {
    max-width: 800px;
  }
  svg-icon.calender_icon {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
  svg-icon.pound_icon {
    position: absolute;
    right: 13px;
    bottom: 10px;
  }
}

