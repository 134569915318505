@import './variables.scss';

.side-menu-wrapper {
  flex: 1 1 auto;

  .asm-menu-anchor {
    border-left: none;
    padding-top: 0px;
    padding-bottom: 0;
  }

  .asm-menu-anchor__icon.menu-icon {
    margin-right: rem(15);
    width: 22px;
    height: 22px;
    display: inline-block;

    &.group-head-icon {
      background-image: url('../assets/icons/group-head-icon.svg');
      background-position: center center;
    }

    &.client-icon {
      background-image: url('../assets/icons/client-icon-grey.svg');
      background-position: center center;
    }

    &.matter-icon {
      background-image: url('../assets/icons/matter-icon-grey.svg');
      background-position: center center;
    }

    &.timesheet-icon {
      background-image: url('../assets/icons/timesheet-icon-grey.svg');
      background-position: center center;
    }

    &.bill-on-completion-icon {
      background-image: url('../assets/icons/bill-on-completion-icon.svg');
      background-position: center center !important;
    }

    &.city-icon {
      background-image: url('../assets/icons/city-icon.svg');
      background-position: center center !important;
    }

    &.enterprise-icon {
      background-image: url('../assets/icons/enterprise-icon.svg');
      background-position: center center !important;
    }

    &.extended-enterprise-icon {
      background-image: url('../assets/icons/extended-enterprise-icon.svg');
      background-position: center center !important;
    }

    &.on-demand-icon {
      background-image: url('../assets/icons/on-demand-icon.svg');
      background-position: center center !important;
    }

    &.on-demand-timesheet-icon {
      background-image: url('../assets/icons/on-demand-timesheet-icon.svg');
      background-position: center center !important;
    }

    &.invoice-icon,
    &.client-invoice-icon {
      background-image: url('../assets/icons/invoice-icon-grey.svg');
      background-position: center center;
    }

    &.disbursement-icon {
      background-image: url('../assets/icons/disbursement-icon-grey.svg');
      background-position: center center;
    }

    &.report-icon {
      background-image: url('../assets/icons/report-icon-grey.svg');
      background-position: center center;
    }

    &.users-icon {
      background-image: url('../assets/icons/users-icon-grey.svg');
      background-position: center center;
    }

    &.configuration-icon {
      background-image: url('../assets/icons/configuration-icon-grey.svg');
      background-position: center center;
    }

    &.role-icon {
      background-image: url('../assets/icons/role-icon.svg');
      background-position: center center !important;
    }

    &.systemconfig-icon {
      background-image: url('../assets/icons/systemconfig-icon.svg');
      background-position: center center !important;
    }

    &.help-icon {
      background-image: url('../assets/icons/brifecase-timer-grey.svg');
      background-position: center center;
    }

    &.logout-icon {
      background-image: url('../assets/icons/logout-icon-grey.svg');
      background-position: center center;
    }

    &.brifecase-timer {
      background-image: url('../assets/icons/brifecase-timer-grey.svg');
      background-position: center center;
    }

    &.book {
      background-image: url('../assets/icons/book.svg');
      background-position: center center;
    }

    &.feedback-list {
      background-image: url('../assets/icons/Feedback-grey.svg');
      background-position: center center;
    }

    &.ai-chat {
      background-image: url('../assets/icons/ai-chat.svg');
      background-position: center center;
    }

    &.client-rates {
      background-image: url('../assets/icons/client-rates-icon.svg');
      background-position: center center;
    }
    &.finance {
      background-image: url('../assets/icons/finance.svg');
      background-position: center center;
    }

    &.home-icon {
      background-image: url('../assets/icons/home-icon.svg');
      background-position: center center;
    }

    .profile-info {
      display: inline-block;
    }
  }

  .mat-sidenav-content {
    > .mat-sidenav-content {
      border-bottom: 1px solid #f9f9f9;
      background-color: $white;

      a {
        padding: 1.125rem 2.0625rem;
        color: $text-color-grey !important;
        border-left: 2px solid transparent;
        height: auto;
        .mdc-list-item__content {
          .asm-menu-anchor__label {
            font-size: rem(16);
            font-weight: 700;
          }
        }
        .mdc-list-item__primary-text {
          display: flex;
          align-items: center;
          color: $text-color-grey !important;
        }
        &:hover {
          color: $white;
          background-color: $primary-color;
          .asm-menu-anchor__label {
            color: $white;
          }
          .toggleIcon {
            filter: brightness(0) invert(1);
          }

          .asm-menu-anchor__icon.menu-icon {
            background-position: -2px -102px;

            &.group-head-icon {
              background-image: url('../assets/icons/group-head-icon-white.svg');
              background-position: center center;
            }

            &.client-icon {
              background-image: url('../assets/icons/client-icon-white.svg');
              background-position: center center;
            }

            &.matter-icon {
              background-image: url('../assets/icons/matter-icon-white.svg');
              background-position: center center;
            }

            &.client-invoice-icon {
              background-image: url('../assets/icons/invoice-icon-white.svg');
              background-position: center center;
            }

            &.timesheet-icon {
              background-image: url('../assets/icons/timesheet-icon-white.svg');
              background-position: center center;
            }

            &.disbursement-icon {
              background-image: url('../assets/icons/disbursement-icon-white.svg');
              background-position: center center;
            }

            &.report-icon {
              background-image: url('../assets/icons/timesheet-report-icon-white.svg');
              background-position: center center;
            }

            &.users-icon {
              background-image: url('../assets/icons/user-icon-white.svg');
              background-position: center center;
            }

            &.logout-icon {
              background-image: url('../assets/icons/logout-icon-white.svg');
              background-position: center center;
            }

            &.brifecase-timer {
              background-image: url('../assets/icons/brifecase-timer.svg');
              background-position: center center;
            }

            &.book {
              background-image: url('../assets/icons/book-white.svg');
              background-position: center center;
            }

            &.feedback-list {
              background-image: url('../assets/icons/Feedback-white.svg');
              background-position: center center;
            }

            &.ai-chat {
              background-image: url('../assets/icons/ai-chat-white.svg');
              background-position: center center;
            }
            &.client-rates {
              background-image: url('../assets/icons/client-rates-white-icon.svg');
              background-position: center center;
            }
            &.finance {
              background-image: url('../assets/icons/finance-white-icon.svg');
            }
            &.home-icon {
              background-image: url('../assets/icons/home-icon-white.svg');
              background-position: center center;
            }
          }
        }
      }

      .active-list-item {
        color: $white;
        background-color: $primary-color;
        .asm-menu-anchor__label {
          color: $white;
        }
        .asm-menu-anchor__icon.menu-icon {
          background-position: -2px -102px;

          &.group-head-icon {
            background-image: url('../assets/icons/group-head-icon-white.svg');
            background-position: center center;
          }

          &.client-icon {
            background-image: url('../assets/icons/client-icon-white.svg');
            background-position: center center;
          }

          &.matter-icon {
            background-image: url('../assets/icons/matter-icon-white.svg');
            background-position: center center;
          }

          &.client-invoice-icon {
            background-image: url('../assets/icons/invoice-icon-white.svg');
            background-position: center center;
          }

          &.timesheet-icon {
            background-image: url('../assets/icons/timesheet-icon-white.svg');
            background-position: center center;
          }

          &.disbursement-icon {
            background-image: url('../assets/icons/disbursement-icon-white.svg');
            background-position: center center;
          }

          &.report-icon {
            background-image: url('../assets/icons/timesheet-report-icon-white.svg');
            background-position: center center;
          }

          &.users-icon {
            background-image: url('../assets/icons/user-icon-white.svg');
            background-position: center center;
          }

          &.logout-icon {
            background-image: url('../assets/icons/logout-icon-white.svg');
            background-position: center center;
          }

          &.brifecase-timer {
            background-image: url('../assets/icons/brifecase-timer.svg');
            background-position: center center;
          }

          &.book {
            background-image: url('../assets/icons/book-white.svg');
            background-position: center center;
          }

          &.feedback-list {
            background-image: url('../assets/icons/Feedback-white.svg');
            background-position: center center;
          }

          &.ai-chat {
            background-image: url('../assets/icons/ai-chat-white.svg');
            background-position: center center;
          }

          &.client-rates {
            background-image: url('../assets/icons/client-rates-white-icon.svg');
            background-position: center center;
          }
          &.home-icon {
            background-image: url('../assets/icons/home-icon-white.svg');
            background-position: center center;
          }
        }
        &.finance {
          background-image: url('../assets/icons/finance-white-icon.svg');
          background-position: center center;
        }
      }

      .mat-expansion-panel {
        border-radius: 0px;

        .mat-expansion-panel-header {
          padding: 1.125rem 2.0625rem;
          height: 100%;
          color: $text-color-grey;
          font-size: 16px;

          .asm-menu-anchor__label {
            font-size: rem(16);
            font-weight: 700;
          }
        }

        .mat-expansion-indicator {
          &::after {
            color: $text-color-grey !important;
          }
        }

        &.active-menu-parent {
          .mat-expansion-panel-header {
            background-color: $primary-color;
            color: $white;

            .invoice-icon {
              background-image: url('../assets/icons/invoice-icon-white.svg');
              background-position: center center;
            }

            .configuration-icon {
              background-image: url('../assets/icons/configuration-icon-white.svg');
              background-position: center center;
            }
          }

          .mat-expansion-indicator {
            &::after {
              color: $white !important;
            }
          }
        }

        .mat-expansion-panel-body {
          padding: 0px;

          .mat-nav-list {
            padding-top: 0px;
          }
        }

        &:hover,
        &:focus {
          .mat-expansion-panel-header {
            background-color: $primary-color;
            color: $white;
          }

          .invoice-icon {
            background-image: url('../assets/icons/invoice-icon-white.svg');
            background-position: center center;
          }

          .configuration-icon {
            background-image: url('../assets/icons/configuration-icon-white.svg');
            background-position: center center;
          }

          .mat-expansion-indicator {
            &::after {
              color: $white !important;
            }
          }
        }
      }

      .mat-expansion-panel-content {
        background: $blue-bg;

        a {
          color: $text-color;
          padding: 1.125rem 2.0625rem 1.125rem 4.375rem;
          font-weight: 700;
          &:hover {
            background: $blue-bg-20;
            color: $text-color;
            &::before {
              background-color: transparent;
            }
          }
          .asm-menu-anchor__label {
            color: $text-color;
            font-weight: 700;
          }
        }
        .active-list-item {
          background: $blue-bg-20;
          color: $text-color;
          .asm-menu-anchor__label {
            color: $text-color;
            font-weight: 700;
          }
        }
        .mat-mdc-list-base {
          padding: 0;
        }
      }
    }
  }
}
