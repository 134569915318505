@import './mixin.scss';

$primary-color: #2B3674;
$logo-blue: #0068FF;
$text-color: #212112;
$text-color-grey: #616161;
$text-color-33: #333333;
$label-color: #8F9BBA;
$placeholder-color: #626D88;
$light-gray-broder: #E7E7E7;
$white: #fff;
$black: #000;
$red: #FE2F01;
$lightRed: #ffd0cb;
$darkRed: #e9594c;
$green-primary: #50BFA4;
$success-green: #009C7B;
$dark-success-green: #3bc279;
$light-success-green: #c5f7dd;
$success-green-2: #C6E5DF;
$error-red: #F48B80;
$error-red-2: #FDDEDB;
$blue-bg: #F4F7FE;
$blue-bg-20: #DAE1F9;
$grey-blue-dark: #152D52;
$grey-blue-bg: #D9E0EB;
$grey-f9: #F9F9F9;
$grey-99: #999999;
$notes-bg: #f0f7fb;
$notes-border: #3498db;
$notes-info-bg: #F3F0FF;
$notes-info-border: #8270DB;

$grey-badge: #E4E4E4;
$orange: #E4A647;
$orange-light: #FFDDC4;

$yellow: #FBBB02;
$yellow-light: #FFF2CC;

$input-border: #d2def9;

$toast-close-button: #212529;

$sora: 'Sora', sans-serif;
$DMsans: 'DM Sans', sans-serif;
$tangerine: 'Tangerine', cursive;

$scroll-thumb: #7784a3;
$scroll-thumb-hover: #555;
$scroll-track: #f4f7fe;


$Desktop: 'only screen and (max-width: 1600px)';
$Desktop-small: 'only screen and (max-width: 1440px)';
$Small-Laptop: 'only screen and (max-width: 1199px)';
$IpadP: 'only screen and (max-width : 1023px)';
$Ipad-992: 'only screen and (max-width : 992px)';
$PhoneL: 'only screen and (max-width: 767px)';
$PhoneP: 'only screen and (max-width: 480px)';

// bootstrap theme customize
$primary: $primary-color;
$theme-colors: (
  "primary": $primary-color,
);
$font-family-sans-serif: $DMsans;
$modal-content-border-radius: 20px;
$badge-font-size: 12px;
$badge-font-weight: 400;
$badge-padding-y: 6px;
$badge-padding-x: 8px;
$badge-border-radius: 10px;