@import './variables.scss'; 

.badge {
    line-height: 20px;
    &-active {
        color: $success-green;
        background-color: $success-green-2;
    }
    &-inactive {
        color:$error-red;
        background-color: $error-red-2;
    }
    &-suspended {
        color:$grey-blue-dark;
        background-color: $grey-blue-bg;
    }
    &-closed {
        color: $text-color;
        background-color: $grey-badge;
    }
    &-draft {
        color: $orange;
        background-color: $orange-light;
    }
    &-pending {
        color: $yellow;
        background-color: $yellow-light;
    }
}