@import './variables.scss';

.toast-container {
    .ngx-toastr {

        &.toast-error,
        &.toast-success {
            background-image: url(../assets/images/right-tick-green.svg);
            background-color: $light-success-green;
            border-left: 10px solid $dark-success-green;
            border-radius: 10px;
            color: $text-color;
            width: 350px;

            .toast-close-button {
                color: $toast-close-button;

                &:focus {
                    opacity: 0.7;
                }
            }
        }
    }
}