@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

label {
  @include text($text-color);
}

*,
body {
  font-variant: normal;
  font-feature-settings: normal;
}

a:hover,
a:focus {
  text-decoration: none;
}

.page-layout {
  display: flex;
  background-color: $blue-bg;


  @media #{$PhoneL} {
    min-width: 767px;
  }

  .page-wrapper {
    width: calc(100% - 290px);
    height: 100vh;
    overflow-y: auto;

    @media #{$IpadP} {
      width: calc(100% - 250px);
    }

    @media #{$PhoneL} {
      overflow-y: hidden;
    }

    .page-wrapper-content {
      padding: 30px;
    }
  }
}

.loader-animation {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: $white, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .input-icon {
    right: auto;
    bottom: auto;
  }
}

.users {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;

    li {
      height: 26px;
      width: 26px;
      border-radius: 100%;
      background-color: #a398b5;
      color: $white;
      border: 1px solid $white;
      text-align: center;
      line-height: rem(24);
      font-size: rem(11);
      letter-spacing: 0.04em;

      +li {
        margin-left: -10px;
      }
    }
  }

  .user-total {
    margin-left: 6px;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(20);
  }
}

// Toast Message Design
body {
  .toast-container {
    .ngx-toastr {
      box-shadow: 0 2px 10px 0px $text-color-33;

      font-size: 14px;
      padding: 8px 15px 8px 50px;
      background-position: 12px center;

      &:hover {
        box-shadow: 0 2px 10px 0px $text-color-33;
      }

      &.toast-error {
        background-image: url(../assets/images/error-icon.svg);
        background-color: $lightRed;
        border-left: 10px solid $darkRed;
        border-radius: 10px;
        color: $text-color;
      }

      &.toast-success {
        background-image: url(../assets/images/right-tick-green.svg);
        background-color: $light-success-green;
        border-left: 10px solid $dark-success-green;
        border-radius: 10px;
        color: $text-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        .toast-close-button {
          right: 0;
          top: 0;
        }

        .toastr-message {
          width: 100%;

          &.invoices-toastr {
            padding-right: 10px;
          }
        }
      }
    }
  }

  // *************** Dialog ***************
  
.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: $DMsans;
}
  // *************** Radio button ***************
  .mat-mdc-radio-group {
    .mat-mdc-radio-button {

      .mdc-radio__outer-circle,
      .mdc-radio__inner-circle {
        border-color: $primary-color !important;
      }

      &.mat-mdc-radio-checked {
        .mdc-radio__background {
          &::before {
            background-color: $primary-color !important;
          }
        }
      }

      .mdc-radio {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .mdc-label {
        margin-bottom: 0;
      }
    }
  }
}

.mat-mdc-checkbox {
  .mdc-label {
    margin-bottom: 0;
    font-size: var(--mat-checkbox-label-text-size);
    font-weight: 400;
  }
}

.content-top-search {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media #{$IpadP} {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  h1 {
    font-family: $DMsans;
    flex: 1 1 auto;

    @media #{$IpadP} {
      margin-bottom: 15px;
    }
  }

  .right-wraper {
    min-width: 470px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media #{$IpadP} {
      max-width: 100%;
    }

    &-search {
      width: 320px;
      margin-right: 20px;
    }

    .add-btn {
      .btn {
        min-width: 130px;
      }
    }
  }
}

.custom-tooltip-action {
  position: relative;

  .custom-tooltip {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 1;
    display: none;
    background: $blue-bg-20;
    min-width: 102px;
    border-radius: 8px;
    padding: 8px 15px;
    box-shadow: 0px 0px 30px rgba(33, 33, 18, 0.1);

    &.invoices {
      top: 6px !important;
      right: 38px !important;
    }

    &::after {
      position: absolute;
      top: -5px;
      right: 8px;
      height: 12px;
      width: 12px;
      background-color: $blue-bg-20;
      transform: rotate(45deg);
      border-radius: 3px;
      content: '';
    }

    span,
    h3 {
      font-size: rem(12);
      font-weight: 500;
      color: $text-color-grey;
      letter-spacing: -0.02em;
      margin-bottom: 0;
    }

    h3 {
      color: $text-color;
    }
  }

  a:hover {
    ~.custom-tooltip {
      display: block;
    }
  }
}

button:hover {
  .custom-tooltip {
    display: block;
    top: 55px;
  }
}

// ********** Calander overlay **********
.cdk-overlay-container {
  z-index: 9999;
}

// ********** Datepicker **********
.mat-primary {
  width: 100%;

  &.mat-form-field-appearance-fill {

    .mat-mdc-form-field-flex {
      padding: 11px 15px 11px 0;
      background-color: $blue-bg;
      border-radius: 10px;

      .mat-mdc-form-field-infix {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;

        .mat-mdc-input-element {
          margin: 0 0 0 38px;
          padding-left: 18px;
          line-height: 24px;
          border-left: 1px solid $blue-bg-20;

          &::placeholder {
            color: $text-color;
            font-size: rem(14);
          }
        }

        .mat-mdc-form-field-label-wrapper {
          top: 0;
          padding-top: 0;
        }

        .mat-icon {
          width: 16px;
          height: 16px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .mat-datepicker-toggle {
        padding: 0 10px 0 11px;
        background-size: 18px 18px;
        left: 0;
        position: absolute;
        width: 94%;
        background-position: left 10px center;

        .mat-mdc-form-field-suffix {
          .mat-mdc-icon-button {
            display: none;
          }
        }
      }
    }

    .mat-mdc-form-field-underline {
      display: none;
    }
  }
}

mat-datepicker-toggle.mat-datepicker-toggle {
  .mat-mdc-icon-button {
    display: none;
  }
}

.ng-select {
  &.ng-select-opened {
    >.ng-select-container {
      background: transparent;
    }
  }

  &.ng-select-disabled {
    >.ng-select-container {
      background-color: transparent !important;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          padding-left: 0 !important;
        }
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) {
      >.ng-select-container {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
}

.Weekly-timesheet-table {
  tbody {
    .expanded-details-row {
      display: none !important;
    }
  }
}

::ng-deep {
  .form-checkbox {
    .form-checkbox-lbl {
      &::after {
        background-color: $white;
      }
    }
  }
}

.header-align-right {
  .mat-sort-header-container {
    justify-content: flex-end;
  }
}

.data-table {
  table {
    thead {
      tr {
        &.mat-mdc-header-row {
          height: auto;
          background: $primary-color;
        }
      }
    }

    tfoot {
      tr {
        &.footer-row {
          background-color: $blue-bg-20;

          td {
            border-top: 0px;
            border-bottom: 0px;
            vertical-align: middle;
            font-weight: 700;
            background: transparent;

            &:first-child {
              border-radius: 15px 0 0 15px;
            }

            &:last-child {
              border-radius: 0 15px 15px 0;
            }
          }
        }
      }
    }
  }
}

.date-input {
  display: flex;
  flex-flow: column;

  .mat-primary {
    padding-bottom: 3px;
  }

  .mat-primary.mat-form-field-appearance-fill {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-icon-suffix {
        align-self: flex-start;
        padding: 0;
        height: 26px;
        position: absolute;
        right: 20px;
        top: 8px;
        width: 95%;

        .mat-datepicker-toggle {
          background-position: right center;
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  &.manual-date-input {
    .mat-primary.mat-form-field-appearance-fill {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          height: 26px;
          position: absolute;
          right: 20px;
          top: 8px;
          width: 95%;

          .mat-datepicker-toggle {
            background-position: right center;
            width: 100%;
          }
        }
      }
    }
  }

  .mat-mdc-form-field-flex {
    padding: 0;
    background: $white;
    display: flex;
    align-items: center;
  }

  .mat-mdc-form-field-infix {
    padding: 0;
    margin: 0;
    width: 100%;
    border: 0px;

    input {
      border: 0;
      padding: 0;
    }
  }

  .mat-mdc-button-wrapper {
    svg {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-underline {
    bottom: 3px;

    &::before {
      background-color: $light-gray-broder;
    }

    .mat-mdc-form-field-ripple {
      height: 1px;
      background-color: $primary-color;
    }
  }
}

.scroll-container {
  max-height: 500px;
}

.listing-table {
  p {
    margin: 30px 0 0;
    text-align: center;
    font-size: rem(18);
  }
}

.pointer {
  cursor: pointer;
}

.default-user-avatar {
  filter: invert(38%) sepia(9%) saturate(5%) hue-rotate(7deg) brightness(97%) contrast(93%);
  opacity: 0.7;
}

// Ellipsis for client name
.client-name-td {
  max-width: 250px;

  &>span {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.description-pattern-error {
  margin-bottom: 30px !important;
}

.no-matter-assigned-error {
  font-size: 14px;
  background-color: $blue-bg;
  padding: 10px 20px;
  border-radius: 15px;
  margin: 0 15px 12px 15px;
  width: 100%;

  &.automatic-time-entry {
    margin: 0px;
  }
}

// Ellipsis for TimeSheet Description
.time-sheet-description,
.description-text,
.text-with-ellipsis,
.text-with-125px {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-with-305px {
  max-width: 305px;
}

.text-with-250px {
  max-width: 250px;
}

.text-with-125px {
  max-width: 125px;
}

.text-with-120px {
  max-width: 120px;
}

.text-width-105px {
  max-width: 105px;
}

.text-with-100px {
  max-width: 100px;
}

.text-with-90px {
  max-width: 90px;
}

.text-with-70px {
  max-width: 70px;
}

.text-with-80px {
  max-width: 80px;
}

.text-with-50px {
  max-width: 50px;
}

.text-with-60px {
  max-width: 60px;
}

.start-timer-btn {
  &:disabled {
    opacity: 0.5;
  }
}

.timer-stop-enabled {
  cursor: pointer !important;
  pointer-events: all !important;
}

.edit-timesheet-time-icon {
  cursor: none;
  pointer-events: none;
}

// *********
.mat-mdc-form-field {
  .mat-mdc-form-field-infix {
    min-height: 24px;
  }

  .mdc-line-ripple,
  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: transparent;
    padding: 0;
  }
}

.mat-mdc-input-element:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.form-field-date {
  .mat-primary.mat-form-field-appearance-fill {
    .mat-mdc-form-field-flex {
      padding: 0;
      background: transparent;

      .mat-mdc-form-field-infix {
        .mat-mdc-input-element {
          margin: 0;
          border: 0;
          padding: 0 0 10px 0;
        }
      }
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      border-bottom: 1px solid $light-gray-broder;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-form-field-icon-suffix {
      align-self: flex-start;
      padding: 0;
      height: 26px;
      position: absolute;
      right: 20px;
      top: 0px;
      width: 95%;

      .mat-datepicker-toggle {
        background-position: right center;
        width: 100%;
        padding: 0;
      }
    }
  }
}

.text-right,
.text-right-field {
  input {
    direction: rtl;
  }
}

.label-text {
  margin: 0 0 8px 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: $text-color-grey;
  min-height: 14px;
}

.info-section {
  display: flex;
  flex-direction: column;

  .label-text {
    font-size: 0.875rem;
  }
}

.form-field-label-text {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3125rem;
  color: $text-color-grey;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

th {
  &.text-align-right {
    .mat-sort-header-container {
      display: flex;
      justify-content: right;
    }
  }
  &.text-align-center {
    text-align: center;
  }
}

.spacer {
  flex: 1;
}

.mat-mdc-checkbox-ripple,
.mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}