@use "sass:math";

@function rem($pixels, $context: 16) {
  @return math.div($pixels, $context) * 1rem; //#{$pixels/$context}rem;
}

//size mixin
@mixin size($height: null, $width: null) {
  height: $height;
  width: $width;
}

@mixin fullsize($max-height: null, $max-width: null) {
  max-height: $max-height;
  max-width: $max-width;
}

//image mixin
@mixin img($type) {
  object-fit: $type;
  height: 100%;
  width: 100%;
}

//text mixin start
@mixin text($color) {
  color: $color;
  font-size: rem(12);
  line-height: rem(18);
  font-weight: 400;
}
@mixin text-m($color) {
  color: $color;
  font-size: rem(14);
  line-height: rem(18);
  font-weight: 400;
}

@mixin text-l($color) {
  color: $color;
  font-size: rem(16);
  line-height: rem(21);
  font-weight: 500;
  @media #{$IpadP} {
    font-size: 14px;
  }
}

@mixin text-h1($color, $bottom) {
  font-size: rem(34);
  line-height: rem(42);
  color: $color;
  margin-bottom: $bottom;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-family: $sora;
  @media #{$IpadP} {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin text-h2($color, $bottom) {
  font-size: rem(22);
  color: $color;
  margin-bottom: $bottom;
  font-weight: 500;
  line-height: rem(28);
  @media #{$IpadP} {
    font-size: rem(20);
    line-height: rem(26);
  }
}
@mixin text-h3($color, $bottom) {
  font-size: rem(18);
  color: $color;
  margin-bottom: $bottom;
  font-weight: 700;
  line-height: rem(24);
  @media #{$IpadP} {
    font-size: rem(16);
    line-height: rem(20);
  }
}

@mixin text-h4($color) {
  color: $color;
  font-size: rem(16);
  line-height: rem(24);
  font-weight: 400;
  @media #{$IpadP} {
    font-size: rem(14);
  }
}
@mixin text-h5($color) {
  color: $color;
  font-size: rem(14);
  line-height: rem(18);
  font-weight: 700;
}
