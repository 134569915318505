.hj-form-field {
  display: block;
  color: #212112;
  font-size: 14px;
  line-height: 1.125rem;
  font-weight: 400;
  margin-bottom: 1rem;

  input,
  textarea {
    padding: 8px !important;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
  }

  textarea {
    resize: vertical;
    min-height: 60px;
  }

  input:disabled,
  textarea:disabled {
    background-color: whitesmoke;
  }

  input:focus,
  textarea:focus {
    outline: 1px solid #86b2ff;
    border: 1px solid #0d64ff;
  }

  .mat-mdc-select {
    font-size: 14px;
    padding: 6px !important;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #212112;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
  }

  // Material overrides
  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: #2b367490;
    color: white;
  }

  .mat-button-toggle {
    min-width: 50px;
  }
}

.mat-mdc-select:focus {
  outline: 1px solid #86b2ff;
  border: 1px solid #0d64ff;
}

.mat-mdc-optgroup-label {
  font-size: 14px;
  font-weight: 500;

  .mdc-list-item__primary-text {
    color: #979eac;
  }
}

.hj-form-field .hj-input-invalid {
  input,
  textarea {
    // outline: 1px solid #ffd0cb;
    // border: 1px solid #f48b80;
    outline: 0;
    border: 1px solid #ef5350;
  }

  .mat-mdc-select {
    outline: 0;
    border: 1px solid #ef5350;
  }

  input:focus,
  textarea:focus {
    outline: 1px solid #86b2ff;
    border: 1px solid #0d64ff;
  }
}

.hj-form-field .error {
  color: #ed4337;
}

.hj-form-section {
  display: grid;
  gap: 4rem;
  margin-top: 2rem;
  grid-template-columns: 2fr 3fr;

  .section-info > p {
    margin: 1rem 0;
    color: #979eac;
  }

  .section-info-header {
    font-size: 16px;
    font-weight: 500;
  }
}

.hj-form-row {
  display: flex;
  flex-direction: row;
}
