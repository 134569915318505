@import './variables.scss';

.content-main {
  background-color: $white;
  border-radius: 20px;
  padding: 25px;
}

.table.mat-mdc-table {
  border-collapse: separate;
  border-spacing: 0;

  * {
    border-style: none;
  }
}

.table-responsive {
  border-radius: 10px;
}

.data-table {
  position: relative;
  display: block;
  overflow: hidden;

  .panel-body {
    @media #{$IpadP} {
      overflow-x: auto;
    }
  }

  table {
    width: 100%;
    margin: 0;
    background-color: transparent;
    min-width: max-content;
    border-radius: 10px;
    border-collapse: collapse;

    thead {
      background: $primary-color;
      position: sticky;
      top: 0;
      z-index: 1;

      tr {
        &.mat-mdc-header-row {
          height: auto;
          background: $primary-color;
        }

        th {
          left: 0;
          color: $white;
          background: $primary-color;
          font-size: rem(12);
          line-height: rem(18);
          letter-spacing: -0.02em;
          border: none;
          padding: 15px;
          outline: none;

          &:first-child {
            border-radius: 10px 0 0 0;
          }

          &:last-child {
            border-radius: 0 10px 0 0;
          }

          .mat-sort-header-arrow {
            color: $white;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 11px 16px;
          border: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.02em;
          vertical-align: middle;

          .accordion-toggle-icon {
            background-color: transparent;
            border: none;
            padding: 0;
            color: $text-color;
            margin-right: 8px;
          }

          .no-data {
            padding: 0;
          }
        }
      }
    }

    > tbody {
      > tr {
        background-color: $white;

        &:nth-child(odd) {
          background-color: $blue-bg;
        }

        &.accordion-toggle-row {
          td {
            .accordion-toggle-icon {
              transform: rotate(180deg);
            }
          }
        }

        &.expanded-details-row {
          height: auto;
        }

        .mat-column-expandedDetail {
          padding: 0;
        }

        .accordian-body-inner {
          padding: 11px 15px;
          width: 100%;
        }
      }

      .accordian-body {
        overflow: hidden;
        display: flex;

        thead {
          tr {
            th {
              background-color: $blue-bg-20;
              color: $text-color;
            }
          }
        }

        tr {
          background-color: transparent;

          td {
            background-color: transparent;
          }
        }
      }
    }

    &.table-white-row {
      > tbody {
        > tr {
          background-color: $white;

          &:nth-child(odd) {
            background-color: $white;
          }
        }
      }
    }
  }

  &.data-table-accordion {
    table {
      > tbody {
        > tr {
          background-color: $white;

          &:nth-child(4n + 1) {
            background-color: $blue-bg;

            + tr.expanded-details-row {
              background-color: $blue-bg !important;
            }
          }
        }

        .accordian-body-inner {
          tbody {
            tr {
              background-color: transparent;

              &:nth-child(4n + 1) {
                background-color: transparent;
              }
            }
          }
        }
      }

      tfoot {
        tr.footer-row {
          background-color: $blue-bg-20;
          border-radius: 15px;
          @include text-h5($text-color);

          &.bg-transparent {
            background-color: transparent;
          }

          td {
            background-color: transparent;
            border-top: none;
            vertical-align: middle;
            border-bottom: none;
            font-weight: 700;

            &:first-child {
              border-radius: 15px 0 0 15px;
            }

            &:last-child {
              border-radius: 0 15px 15px 0;
            }
          }
        }
      }
    }
  }

  .table-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .table-action-item {
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .table-pagination {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    .pagination {
      .page-item {
        padding: 0 5px;

        .page-link {
          color: $primary-color;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: 0;
          font-size: 14px;
          line-height: 30px;
          padding: 0;

          &:focus {
            outline: 0;
            box-shadow: inherit;
          }
        }

        &.active {
          .page-link {
            color: $white;
            background-color: $primary-color;
            border-color: $primary-color;
          }
        }
      }
    }
  }
  .table-action-col {
    overflow: visible;
  }
}

.timesheet-block {
  background-color: $white;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;

  .timesheet-table {
    width: auto;

    tr,
    td {
      background-color: $white !important;
    }

    td {
      padding: 11px 15px;
      border: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: $text-color;
      vertical-align: middle;
      width: 200px;

      &:first-child {
        width: 280px;
      }

      &:nth-child(2) {
        width: 114px;
      }

      &:last-child {
        width: auto;
      }
    }
  }

  &.blue-bg {
    background-color: $blue-bg;

    .timesheet-table {
      width: 100%;

      tr,
      td {
        background-color: $blue-bg !important;
      }
    }
  }
}

.time-entry {
  .accordian-body-inner {
    padding: 0 !important;
  }

  &-table {
    tr {
      td {
        padding: 0 !important;
      }
    }
  }

  &-wrap {
    display: flex;
    align-items: center;
    background-color: $blue-bg-20;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 20px 30px;

    label {
      font-weight: 700;
    }

    .form-field {
      width: calc(60% - 30px);
      margin-bottom: 30px;

      input {
        border-bottom: 1px solid $text-color-grey;
      }
    }

    .ng-select--filled {
      width: 40%;

      .ng-select {
        padding-top: 0;
        padding-left: 0;
        background-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid $text-color-grey;
        color: $text-color-grey;

        .ng-value,
        .ng-placeholder {
          color: $text-color-grey !important;
        }
      }
    }

    .action-wrap {
      width: 100%;
      text-align: right;

      .btn {
        max-width: 262px;
        width: 100%;
      }
    }
  }

  .no-data-found {
    margin: 20px 0 0;
    text-align: center;
  }
}

.mat-mdc-table-sticky {
  background: inherit;
}

.mat-mdc-no-data-row {
  td {
     padding: 17px;
  }
}

.tab-sticky-content .mat-mdc-tab-body-wrapper {
  overflow: visible !important;
}
.tab-sticky-content .mat-mdc-tab-body-active {
  overflow: visible !important;
}
.tab-sticky-content .mat-mdc-tab-body-content {
  overflow: visible !important;
}