@import './variables';

.invoice-action-list {
    .custom-tooltip-action {
        .custom-tooltip {
            padding: 12px 11px;
            width: 106px;
            text-align: center;
            font-size: 12px;
        }
    }
}

.success-modal-body {
    h5 {
        margin-bottom: 40px;
    }
}

.preview-pdf-modal {
    .modal-dialog {
        max-width: 980px;
    }

    .modal-content {
        .close {
            width: 26px;
            position: absolute;
            right: 30px;
            z-index: 99;
        }

        .modal-body {
            padding-top: 40px;
        }
    }
}

.city-wip-table {
    table {
        tr {
            cursor: pointer;
        }
    }
}

.custom-invoices-tooltip {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $blue-bg-20;
    padding: 14px;
    border-radius: 20px;
    align-items: start;
    text-wrap: wrap;
    color: $text-color-grey;
    font-size: 12px;
}

.manage-invoices {

    .btn-outline-primary {
        &:last-child {
            border-left: 1px solid $white;
        }

        &:disabled {
            cursor: no-drop;

            &:hover {
                color: #2b3674;
            }
        }
    }

    .table-action-item {
        cursor: pointer;
    }

    .invoices {
        .nav-tabs {
            border-bottom: none;

            li {
                a {
                    border: none;
                    padding: 20px;
                    letter-spacing: -0.02em;
                    @include text-h3($text-color-grey, 0);
                    border-radius: 20px 20px 0 0;

                    &.active {
                        border: none;
                        color: $text-color;
                    }
                }
            }
        }

        .tab-content {
            margin-top: 0;
            padding: 30px 25px;
            background-color: $white;
            border-radius: 0px 20px 20px 20px;

            table {
                .accordian-body {
                    .mat-column-description {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }

        .table-action {
            a {
                margin-left: 30px;
            }

            &.invoice-action-list {
                a {
                    margin-left: 15px;
                }

                .download-tooltip {
                    .custom-tooltip {
                        width: 195px;
                    }
                }
            }

            .table-action-item {
                margin-left: 0px;
            }
        }

        .custom-tooltip-action {
            .custom-tooltip {
                position: absolute;
                top: -11px;
                right: 30px;
                padding: 6px 8px;
                line-height: 18px;

                &::after {
                    position: absolute;
                    top: 16px;
                    right: -5px;
                    height: 12px;
                    width: 12px;
                    background-color: #dae1f9;
                    transform: rotate(-44deg);
                    border-radius: 3px;
                    content: '';
                }
            }
        }
    }
}

.edit-invoice-modal {
    .modal-dialog {
        max-width: 800px;
    }

    .edit-invoice-row {
        margin-bottom: 30px;

        input {
            &.edit-row-input {
                width: 100%;
                background-color: transparent;
                border-bottom: 1px solid $light-gray-broder;
            }
        }

        .form-field {
            margin-bottom: 5px !important;

            input[type=text] {
                padding: 0px 20px 10px 20px !important;
                box-sizing: border-box;
            }

            input[type=number] {
                padding: 0 0 10px 0 !important;
            }

            &.disbursement-grid {
                input[type=text] {
                    padding: 0px 35px 8px 0px !important;
                }
            }

            &.invoice-till-date input {
                padding: 0px !important;
            }
        }

        svg-icon.pound_icon {
            position: absolute;
            right: 13px;
            bottom: 10px;
        }
    }

    .modal-inner-title {
        margin-bottom: 12px;
        font-size: rem(14);
        font-weight: 700;
        line-height: rem(21);
        color: $text-color;
    }

    .form-radiogroup {
        display: flex;
        align-items: center;

        .form-check {
            margin-right: 70px;
            display: flex;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }

            .form-check-input {
                margin-top: 0;
            }

            .form-check-label {
                font-size: rem(14);
                font-weight: 700;
                line-height: rem(21);
                color: $text-color;
            }
        }
    }

    .total-box {
        padding: 25px;
        background-color: $blue-bg;
        border-radius: 16px;
        font-size: rem(14);
        font-weight: 500;
        line-height: rem(21);
        color: $text-color;

        .total-box-row {
            margin-bottom: 5px;
        }

        .total-last-row {
            margin-top: 5px;
            padding: 10px;
            border-radius: 8px;
            background-color: $blue-bg-20;
        }

        .total-last-row,
        .total-box-row {
            .amount-column {
                padding: 0px 35px 0px 20px !important;
                box-sizing: border-box;

                .pound_icon {
                    position: absolute;
                    right: 13px;
                    bottom: 2px;
                }
            }
        }

        .total-amount-error-msg {
            padding-top: 5px;
        }

        .amount-due {
            margin: 0 -25px;
        }
    }

    .apply-vat-checkbox {
        display: flex;
        align-items: flex-end;

        .form-checkbox-lbl {
            padding-left: 25px !important;
            font-size: rem(14) !important;
            font-weight: 700 !important;
            line-height: rem(21) !important;
            color: $text-color !important;

            &::after {
                top: 2px !important;
            }
        }
    }

    .data-table {
        table {
            tr {

                th,
                td {
                    .form-checkbox {
                        .form-checkbox-lbl {
                            &::after {
                                top: 3px;
                            }
                        }
                    }
                }
            }

            tfoot {
                tr {
                    &.footer-row {
                        td {
                            padding: 11px 15px;
                        }
                    }
                }
            }
        }
    }

    .form-field {
        label {
            font-size: 0.875rem;
        }
    }
}

.on-demand-edit-invoice-modal {
    .pointer {
        cursor: pointer;
    }

    .amount-column {
        svg-icon.pound_icon {
            right: 19px;
        }

        box-sizing: border-box;
        padding-right: 35px;
    }

    .form-field {
        &.vat-amount {
            input[type=text] {
                box-sizing: border-box;
                padding: 0px 35px 10px 0px;
                font-size: 1rem;
            }
        }

    }

    .date-selection-fields {
        .form-field {
            margin-bottom: 15px;
        }
    }

    .form-check-input {
        width: 15px;
        height: 15px;
    }

    .text-right-field {
        .text-right-input {
            padding: 0 30px 15px 20px !important;
        }

        .pound_icon {
            &.text-right-currency {
                bottom: 14px;
            }
        }

        .pound-currency-icon {
            top: -49px;
            left: 205px;
        }
    }

    svg-icon {
        &.pound_icon {
            position: absolute;
            bottom: 38px;
            right: 20px;
        }

        &.calendar-icon {
            position: relative;
            top: -38px;
            left: 138px;
        }

        &.user-icon {
            position: relative;
            top: -36px;
            left: 326px;
        }

        &.pound-currency-icon {
            position: relative;
            top: -50px;
            left: 200px;
        }

        &.document-icon {
            position: relative;
            top: -33px;
            left: 330px;
        }
    }

    .form-check {
        padding-left: 1.5rem;
    }
}